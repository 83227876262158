import { observable } from "mobx";
const APP_VAR_PREFIX = 'METAPAGE_';

class AppState
{
    @observable appname = "🎈MetaPage By Easy";
    
    @observable meta_code = "";
    @observable tpl_code = "";
    @observable out_code = "";
    @observable function_code = "";
    @observable layout = "col";

    @observable langs = ['php','javascript','css','markdown','mysql','dart','go','graphql','html','java','kotlin','less','lua','pug','python','ruby','rust','shell','swift','twig','typescript','yaml'];
    @observable current_lang = "javascript";

    @observable prekey = '';
    
    to_save_vars = ['meta_code','tpl_code','out_code','function_code','current_lang','layout'];
    
    constructor()
    {
        // this.load_vars();
    }

    load_vars()
    {
        this.to_save_vars.forEach( v =>
        {
            const ret = window.localStorage.getItem(APP_VAR_PREFIX+'-'+this.prekey+'-'+v);
            if( ret ) this[v] = JSON.parse( ret );
        });
    }

    save_vars( field = null )
    {
        const vars_array = field === null ? this.to_save_vars : [field];
        vars_array.forEach( v =>
        {
            window.localStorage.setItem(APP_VAR_PREFIX+'-'+this.prekey+'-'+v, JSON.stringify( this[v] ));
        });
    }

    set_var( field , value = null )
    {
        this[field] = value;
        this.save_vars(field);
    }

    save_var( field , value = null )
    {
        window.localStorage.setItem(APP_VAR_PREFIX+'-'+this.prekey+'-'+field, JSON.stringify( value ));
    }
}

export default new AppState();