import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import 'react-mosaic-component/react-mosaic-component.css';
import { Mosaic } from 'react-mosaic-component';
import {
    Alignment,
    Button,
    Classes,
    H5,
    Navbar,
    NavbarDivider,
    NavbarGroup,
    NavbarHeading,
    HTMLSelect,
} from "@blueprintjs/core";
import MonacoEditor from 'react-monaco-editor';
import ejs from 'ejs';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { saveAs } from 'file-saver';
import ReactFileReader from 'react-file-reader';

@withRouter
@inject("store")
@observer
export default class Editor extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    componentDidMount()
    {
        window.addEventListener( 'keydown' , (e) => this.keydown(e)  );
        window.addEventListener( 'resize' , ()=>window.location.reload() );

        const keyname = this.props.match.params.key || "" ;
        // console.log( keyname );
        this.props.store.prekey = keyname;
        this.props.store.load_vars();

        if( this.props.store.meta_code.length < 1 && this.props.store.tpl_code.length < 1 && this.props.store.tpl_code.length < 1 && this.props.store.out_code.length < 1  )
        {
            this.props.store.meta_code = `
{
    "author":"Easy",
    "contacts":
    [
        {"HP":"https://ftqq.com"},
        {"weibo":"https://weibo.com"},
        {"twitter":"https://twitter.com/easychen"}
    ]
}
            `;

            this.props.store.tpl_code = `
/* MetaPage 由 <%=author%> 开发，方糖气球🎈（<%=this.first(contacts).HP%>）出品。
模板部分采用 ejs 语法( https://github.com/mde/ejs )  
*/
`;
            this.props.store.function_code = `
{
    first( contacts )
    {
        return contacts[0];
    }
}                
            `;

            this.props.store.out_code = `
// 点击「生成」获取最终代码。点「复制」将其复制到剪贴板。网址后输入不同的路径如：/abc 可以生成不同的 MetaPage。数据存储在浏览器的 localStorage，可通过菜单导入和导出。             
            `;

            this.props.store.save_vars();
        }        
    }

    componentWillUnmount()
    {
        window.removeEventListener( 'keydown' , (e) => this.keydown(e)  );
        window.removeEventListener( 'resize' , ()=>window.location.reload() );
    }

    keydown( e )
    {
        // console.log( e );
        if( e.metaKey && e.key == 's' )
        {
            this.build();
            e.stopPropagation();
            e.preventDefault();

        }
    }

    editorDidMount(editor, name) 
    {
        //console.log( name , this.refs );
        window.setTimeout( ()=>{
            if( this.refs[name] )
        this.refs[name].editor.layout();
        }, 100 );
        
         
        // if( !this.editors ) this.editors = [];
        // this.editors.push(editor);
        // console.log( this.editors );
    }

    onChange( value , type )
    {
        this.props.store.set_var(type + '_code', value);
    }

    async build()
    {
        // 构建代码
        let meta = JSON.parse( this.props.store.meta_code );
        let out = '';
        let cnt = {};
        
        if( this.props.store.function_code.length > 0 )
        {
            // 将其创建为 dataurl，然后用 import 载入进来
            try {
                eval(`cnt = ${this.props.store.function_code}`);
                // console.log( cnt.get_book );
            } catch (error) {
                out = error.toString();
                this.props.store.set_var('out_code', out );
                return false;
            }
        }
        

        try {
            out = ejs.render( this.props.store.tpl_code,meta, {"context":cnt} );
        } catch (error) {
            out = error.toString();
        }
        //console.log( JSON.parse(this.props.store.meta_code) );
        this.props.store.set_var('out_code', out );
    }

    export()
    {
        let data = {};

        this.props.store.to_save_vars.forEach( thevar => data[thevar] = this.props.store[thevar] );

        
        saveAs( new Blob([JSON.stringify(data)], {
            type: "text/json;charset=utf-8"
          }) , 'MetaPage.'+(this.props.prekey? "."+this.props.prekey :"")+'.json' )  ;
    }

    async import( files )
    {
        const thefile = files[0];
        const data = JSON.parse(await thefile.text());
        if( data )
        {
            this.props.store.to_save_vars.forEach( thevar => 
            {
                this.props.store[thevar] = data[thevar];
            });
            this.props.store.save_vars();
        }
    }

    // componentDidUpdate( prevProps )
    // {
    //     if (this.props.data !== prevProps.data) 
    //     {
    //         this.relayout();    
    //     }    
    // }

    relayout()
    {
        // ['meta','function','out','tpl'].forEach( thetype => {
        //     this.refs[thetype].editor.layout(); 
        // } );

        window.location.reload();
    }

    layout()
    {
        this.props.store.save_var( 'layout' , this.props.store.layout == 'col' ? 'row' : 'col' );

        window.location.reload();
        //window.setTimeout(()=>this.relayout(), 100);
    }

    render()
    {
        
        const metabox = <div className="ft-col row2">
            <div className="json">
            <Navbar className={"bp3-dark"}>
                <NavbarGroup >
                    <NavbarHeading>MetaPage · 元数据 · JSON</NavbarHeading>
                    <Button className={Classes.MINIMAL} icon="pivot-table" onClick={()=>this.layout()}/>
                    {/* <Button className={Classes.MINIMAL} icon="home" text="Home" /> */}
                </NavbarGroup>
            </Navbar>
            <MonacoEditor
            ref="meta"
            width="100%"
            height="calc( 100% - 50px )"
            language="json"
            theme="vs-dark"
            value={this.props.store.meta_code}
            options={{
                    "fontSize":14,
                    "lineNumbers":"off",
                    "wordWrap":"on",
                    "minimap":{"enabled":false}
                }}

            editorDidMount={(...porps)=>this.editorDidMount({...porps},'meta')} 
            onChange={ value => this.onChange( value , 'meta' )}

            />
            </div>
            <div className="function">
            <Navbar className={"bp3-dark"}>
                <NavbarGroup >
                    <NavbarHeading>公用对象 · this </NavbarHeading>
                    {/* <NavbarDivider /> */}
                    {/* <Button className={Classes.MINIMAL} icon="home" text="Home" /> */}
                </NavbarGroup>
            </Navbar>
            <MonacoEditor
            ref="function"
            width="100%"
            height="calc( 100% - 50px )"
            language="javascript"
            theme="vs-dark"
            value={this.props.store.function_code}
            options={{
                    "fontSize":14,
                    "lineNumbers":"off",
                    "wordWrap":"on",
                    "minimap":{"enabled":false}
                }}

            editorDidMount={(...porps)=>this.editorDidMount({...porps},'function')} 
            onChange={ value => this.onChange( value , 'function' )}

            />
            </div>
        </div>;

        const tplbox = <div className="ft-col">
        <Navbar className={"bp3-dark"}>
            <NavbarGroup >
                <NavbarHeading>模板</NavbarHeading>
                <NavbarDivider />
                <HTMLSelect value={this.props.store.current_lang} options={this.props.store.langs} onChange={(e)=>
                    {
                        if( e.target.value )
                            this.props.store.set_var( 'current_lang' , e.target.value );
                        // console.log(e.target.value);
                    }}/>
                
                {/* <Button className={Classes.MINIMAL} icon="home" text="Home" /> */}
            </NavbarGroup>
        </Navbar>
        <MonacoEditor
        ref="tpl"
        width="100%"
        height="calc( 100% - 50px )"
        language={this.props.store.current_lang}
        theme="vs-dark"
        value={this.props.store.tpl_code}
        options={{
                "fontSize":14,
                "lineNumbers":"off",
                "wordWrap":"on",
                "minimap":{"enabled":false}
            }}
        editorDidMount={(...porps)=>this.editorDidMount({...porps},'tpl')} 
        onChange={ value => this.onChange( value , 'tpl' )}

        />
    </div>;
        const outbox = <div className="ft-col">
        <Navbar alignment={Alignment.RIGHT} className={"bp3-dark"}>
            <NavbarGroup >
            <Button className={Classes.MINIMAL} icon="build" text="生成" onClick={()=>this.build()}/>
            <CopyToClipboard text={this.props.store.out_code}>
                <Button className={Classes.MINIMAL} icon="clipboard" text="复制"  />
            </CopyToClipboard>
            
            <Button className={Classes.MINIMAL} icon="download"  onClick={()=>this.export()} text="导出"/>

            <ReactFileReader fileTypes={[".json"]} handleFiles={(e)=>this.import(e)}><Button className={Classes.MINIMAL} icon="upload" text="导入"/></ReactFileReader>
            
            
                
                
            </NavbarGroup>
        </Navbar>
        <MonacoEditor
        ref="out"
        width="100%"
        height="calc( 100% - 50px )"
        language={this.props.store.current_lang}
        theme="vs-dark"
        value={this.props.store.out_code}
        options={{
                "fontSize":14,
                "lineNumbers":"off",
                "wordWrap":"on",
                "minimap":{"enabled":false}
            }}
        editorDidMount={(...porps)=>this.editorDidMount({...porps},'out')}
        onChange={ value => this.onChange( value , 'out' )} 

        />
    </div>;

        const layout_class = this.props.store.layout == 'col' ? 'col' : 'row';
        
        const main = <div className={"editorbox " + layout_class }>
            {metabox}{tplbox}{outbox}
        </div>;
        return <DocumentTitle title={this.props.store.appname}>{main}</DocumentTitle>;
    }
}